import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend],
      loadPath: "/assets/data/locales/{{lng}}.json",
    },
    lng: "nl", // Force language to Dutch
    fallbackLng: "en",
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
  });

export default i18n;
